import { gql } from '@apollo/client'

export const NEWS_TYPE_FIELDS = gql`
  fragment NewsTypeFields on NewsType {
    id
    title
    content
    createdAt
    createdById
    lastModifiedById
    published
    publishedAt
    updatedAt
  }
`
