import React from 'react'

import { Box, Link, Tooltip } from '@mui/material'
import { CodeBlock } from 'components/codeBlock'

import { Subtitle, Title, YoutubeCustomizedIcon } from './styles'

function LinuxInstructions() {
  return (
    <Box width="100%">
      <Box alignItems="center" display="flex" sx={{ mt: 4 }}>
        <Title>First-time installation</Title>
        <Tooltip arrow title={'Click to see the video instructions.'}>
          <Link
            href={'https://youtu.be/RHi33iv-IAs'}
            sx={{ display: 'flex', ml: 1, mt: '3px' }}
            target="_blank"
          >
            <YoutubeCustomizedIcon />
          </Link>
        </Tooltip>
      </Box>
      <Subtitle>1. Install CURL:</Subtitle>
      <CodeBlock code={'sudo apt install curl'} />
      <Subtitle>2. Install NVM: </Subtitle>
      <CodeBlock
        code={
          'curl https://raw.githubusercontent.com/creationix/nvm/master/install.sh | bash'
        }
      />
      <CodeBlock code={'source .bashrc'} />
      <Subtitle>3. Install Node v20.9.0 using NVM: </Subtitle>
      <CodeBlock code={'nvm install  v20.9.0'} />
      <Subtitle>4. Install NPM: </Subtitle>
      <CodeBlock code={'sudo apt install npm'} />
      <Subtitle>5. Install TrustMNW CLI: </Subtitle>
      <CodeBlock code={'npm install @morpheusnetwork/trustmnw-cli -g'} />
      <Subtitle sx={{ mt: '-13px' }}>
        Note: Updating can also be done using this command.
      </Subtitle>
      <Subtitle>6. Run Morpheus CLI:</Subtitle>
      <CodeBlock code={'trustmnw-cli login'} />
      <Subtitle>
        The system will ask for your email address, and you will need to enter
        the verification code sent to your email address.
      </Subtitle>
      <Subtitle>7. Check status and confirm if it’s running:</Subtitle>
      <CodeBlock code={'trustmnw-cli status'} />
      <Subtitle>
        To exit status - just close tab of your terminal or use hotkey “ctrl +
        c”.
      </Subtitle>
      <Subtitle>
        8. Check-in 10 minutes on the web if it says Node is running.
      </Subtitle>
      <Title id="update" sx={{ mt: 4 }}>
        Update
      </Title>
      <Subtitle>1.Update CLI:</Subtitle>
      <CodeBlock code={'npm update @morpheusnetwork/trustmnw-cli -g'} />
      <Subtitle>2.Restart CLI:</Subtitle>
      <CodeBlock code={'trustmnw-cli restart'} />
      <Subtitle>3. Help command:</Subtitle>
      <CodeBlock code={'trustmnw-cli restart help'} />
    </Box>
  )
}

export default LinuxInstructions
