import { gql } from '@apollo/client'

import { QUEST_FIELDS } from './fragments'

export const GET_PUBLISHED_QUESTS = gql`
  query getPublishedQuestList(
    $filter: Object
    $order: String
    $page: Int
    $search: String
    $take: Int
  ) {
    getPublishedQuestList(
      filter: $filter
      order: $order
      page: $page
      search: $search
      take: $take
    ) {
      data {
        ...QuestFields
      }
      meta {
        hasNextPage
        hasPreviousPage
        itemCount
        page
        pageCount
        take
      }
    }
  }
  ${QUEST_FIELDS}
`

export const QUEST = gql`
  query Quest($id: ID!) {
    quest(id: $id) {
      ...QuestFields
    }
  }
  ${QUEST_FIELDS}
`

export const QUEST_DAILY_STATS = gql`
  query questDailyStats {
    questDailyStats {
      completedQuests
      publishedQuests
    }
  }
`

export const QUEST_BY_PHASE_ID_STATS = gql`
  query questByPhaseIdStats($phaseId: String!) {
    questByPhaseIdStats(phaseId: $phaseId) {
      completedQuests
      publishedQuests
    }
  }
`

export const VALIDATOR_POINTS = gql`
  query validatorPoints {
    validatorPoints
  }
`
