import React, { useCallback, useEffect, useMemo, useState } from 'react'
import OneSignal from 'react-onesignal'
import { useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { Snackbar, ThemeProvider } from '@mui/material'
import { Alert } from 'components/alert'
import { AlertMessage } from 'components/alert/types'
import {
  NewsModal,
  QuestModal,
  SetUsernameModal,
} from 'components/blocks/modals'
import HomeScreenGuide from 'components/HomeScreenGuide'
import RestoreNavigation from 'components/RestoreNavigation'
import WrongAccount from 'components/WrongAccount'
import { ONESIGNAL } from 'config'
import { ALERT_DELAY, URL_PARAMS } from 'constants/params'
import { AppContext } from 'context/AppContext'
import { AuthorizedContext } from 'context/authorizedContext'
import { CurrentPhaseContext } from 'context/phaseContext'
import { Mode, ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { BigNumber, ethers } from 'ethers'
import { CURRENT_PHASE } from 'graphql/phase/queries'
import { ME } from 'graphql/validators/queries'
import { UserBalance } from 'hoc/userBalance'
import ms from 'ms'
import AuthorizedRouter from 'router/authorizedRoutes'
import NotAuthorizedRouter from 'router/notAuthorizedRoutes'
import { getTheme, setTheme } from 'services/theme'
import { getToken } from 'services/token'
import theme from 'utils/theme'

const PHASE_POLL_INTERVAL = ms('2m')

function App() {
  const [mode, setMode] = useState<Mode>(
    getTheme() === null ? Mode.DARK : getTheme(),
  )
  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const [searchParams] = useSearchParams()
  const questId = searchParams.get(URL_PARAMS.QUEST)
  const newsId = searchParams.get(URL_PARAMS.ANNOUNCEMENT)

  const [balance, setBalance] = useState<BigNumber>(
    ethers.utils.parseEther('0'),
  )
  const [balanceUpdating, setBalanceUpdating] = useState<boolean>(true)

  const [authorized, setAuthorized] = useState<boolean>(getToken() !== null)

  const [usernameModal, setUsernameModal] = useState({ isOpen: false })

  const { data, loading, refetch } = useQuery(ME, {
    skip: !getToken(),
  })

  const { data: currentPhaseData, loading: currentPhaseLoading } = useQuery(
    CURRENT_PHASE,
    { pollInterval: getToken() ? PHASE_POLL_INTERVAL : 0 },
  )

  const me = useMemo(() => data?.me, [data])

  useEffect(() => {
    if (!me && window?.WorklairWidget) {
      window.WorklairWidget.hide()
    }
    if (me && window?.WorklairWidget) {
      window.WorklairWidget.show()
    }
  }, [me])

  useEffect(() => {
    if (window?.WorklairWidget && me?.email) {
      window.WorklairWidget.authorize({
        email: me.email,
        fullName: me?.name || 'Validator',
      })
    }

    // Note: because open everytime, when change other object fields
  }, [me?.email, me?.name])

  const [oneSignalInitialized, setOneSignalInitialized] =
    useState<boolean>(false)

  const initializeOneSignal = useCallback(
    async (userId: string) => {
      if (!ONESIGNAL.SAFARI_WEB_ID || oneSignalInitialized) return

      setOneSignalInitialized(true)

      await OneSignal.init({
        appId: ONESIGNAL.APP_ID,
        safari_web_id: ONESIGNAL.SAFARI_WEB_ID,
        autoResubscribe: true,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: true,
        },
      })
      await OneSignal.login(userId)
      await OneSignal.Slidedown.promptPush()
    },
    [oneSignalInitialized],
  )

  useEffect(() => {
    const initialize = async () => {
      if (me) {
        await initializeOneSignal(me.id)
      }
    }

    initialize().then()
  }, [me, initializeOneSignal])

  // note: for tooltip which used when there’s limited space for the date label on the X-axis,
  // abbreviated with '...' on the chart.
  useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
      .goog-tooltip * {
        padding: 8px !important;
        background-color: ${mode === 'light' ? '#fff' : '#262546'}!important;
        border: none !important;
        z-index: 1 !important;
        border-radius: 8px !important;
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [mode])

  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode() {
        setMode(prevMode => (prevMode === Mode.LIGHT ? Mode.DARK : Mode.LIGHT))
        setTheme(mode === Mode.LIGHT ? Mode.DARK : Mode.LIGHT)
      },
    }),
    [mode],
  )

  const handleSetUsername = useCallback(() => {
    setUsernameModal({ isOpen: true })
  }, [])
  const handleCloseUsernameModal = useCallback(() => {
    setUsernameModal({ isOpen: false })
  }, [])

  const handleCloseAlert = useCallback(
    () => setAlert(prevState => ({ ...prevState, isOpen: false })),
    [],
  )

  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  const userBalanceValues = useMemo(
    () => ({
      balance,
      setBalance,
      updating: balanceUpdating,
      setUpdating: setBalanceUpdating,
    }),
    [balance, setBalance, balanceUpdating, setBalanceUpdating],
  )

  const appContextValue = useMemo(
    () => ({
      me,
      refetchMe: refetch,
      showAlert: handleAlert,
      setUsername: handleSetUsername,
    }),
    [handleAlert, handleSetUsername, me, refetch],
  )

  // todo need only for quests
  const phaseContextValue = useMemo(
    () => ({
      currentPhase: {
        ...currentPhaseData?.currentPhase,
      },
    }),
    [currentPhaseData],
  )

  const authContextValue = useMemo(
    () => ({
      authorized,
      setAuthorized,
    }),
    [authorized],
  )

  const appTheme = useMemo(() => theme(mode), [mode])

  if (loading || currentPhaseLoading) return null

  if (authorized) {
    return (
      <ThemeProvider theme={appTheme}>
        <ThemeContext.Provider value={colorMode}>
          <AuthorizedContext.Provider value={authContextValue}>
            <AppContext.Provider value={appContextValue}>
              <CurrentPhaseContext.Provider value={phaseContextValue}>
                <UserBalanceContext.Provider value={userBalanceValues}>
                  <UserBalance>
                    <RestoreNavigation />
                    <AuthorizedRouter confirmedTerms={me?.confirmedTerms} />
                    <SetUsernameModal
                      isOpen={usernameModal?.isOpen}
                      onClose={handleCloseUsernameModal}
                    />
                    <WrongAccount />
                    <HomeScreenGuide />
                    {questId && <QuestModal questId={questId} />}
                    {newsId && <NewsModal newsId={newsId} />}
                    <Snackbar
                      autoHideDuration={ALERT_DELAY}
                      open={alert?.isOpen}
                      onClose={handleCloseAlert}
                    >
                      <Alert severity={alert?.color} onClose={handleCloseAlert}>
                        {alert.title}
                      </Alert>
                    </Snackbar>
                  </UserBalance>
                </UserBalanceContext.Provider>
              </CurrentPhaseContext.Provider>
            </AppContext.Provider>
          </AuthorizedContext.Provider>
        </ThemeContext.Provider>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme(mode)}>
      <ThemeContext.Provider value={colorMode}>
        <AuthorizedContext.Provider value={authContextValue}>
          <CurrentPhaseContext.Provider value={phaseContextValue}>
            <NotAuthorizedRouter />
            <HomeScreenGuide />
          </CurrentPhaseContext.Provider>
        </AuthorizedContext.Provider>
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}

export default App
