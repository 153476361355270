import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'

export const ModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  padding: '50px 50px 20px 50px',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000021',

  outline: 'none',
  '&:focus': {
    outline: 'none',
  },

  [theme.breakpoints.down('md')]: {
    padding: '30px 16px',
  },
}))

export const ModalCloseButton = styled(Button)(() => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
  color: '#253566',
  minWidth: 'unset',
  padding: '0',
  width: '24px',
  height: '24px',
  zIndex: 10,
}))

export const ModalTitle = styled(Box)(() => ({
  position: 'absolute',
  top: '15px',
  left: '15px',
  color: '#fff',
  fontWeight: '700',
  lineHeight: '32px',
  fontSize: '20px',
}))
