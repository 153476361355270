import React, { useCallback, useMemo, useState } from 'react'
import Confetti from 'react-confetti'
import { useResizeDetector } from 'react-resize-detector'

import { useMutation, useQuery } from '@apollo/client'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import StarIcon from '@mui/icons-material/Star'
import { Box, Divider, Tooltip, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as ChainIcon } from 'assets/icons/chain.svg'
import { ReactComponent as UnionIcon } from 'assets/icons/union.svg'
import { twitterLogo } from 'assets/logos'
import { AlertType } from 'components/alert/types'
import { TwitterActivity } from 'components/blocks/twitterActivity'
import ExpandableText from 'components/expandableText'
import Text from 'components/ui/Text'
import { useAppContext } from 'context/AppContext'
import { useCurrentPhaseContext } from 'context/phaseContext'
import { SubmitButton } from 'globalStyles'
import { VERIFY_QUEST } from 'graphql/quests/mutations'
import {
  QUEST,
  QUEST_BY_PHASE_ID_STATS,
  QUEST_DAILY_STATS,
} from 'graphql/quests/queries'
import { updatePublishedQuestQuery } from 'graphql/quests/updaters'
import { ME } from 'graphql/validators/queries'
import { useResponsiveLayout } from 'hooks'
import { DateTime } from 'luxon'
import EventBus from 'services/eventBus'
import { QuestActivity, VerificationQuestStatus } from 'types/quest'
import { redirectToTwitterAuth } from 'utils/auth'
import { getDate } from 'utils/date'
import { getErrorMessage } from 'utils/error'

import {
  ActivitiesContainer,
  ActivitiesWrapper,
  BackgroundImage,
  GradientWrapper,
  HeaderWrapper,
} from './styles'

// todo need update description text
export const QUEST_ACTIVITIES: QuestActivity[] = [
  {
    icon: <FavoriteBorderIcon color="secondary" />,
    title: 'Like this post',
    description: 'Activity description goes here',
    pointsCount: 1,
    intentLink: 'https://twitter.com/intent/like?tweet_id=',
  },
  {
    icon: <ShareOutlinedIcon color="secondary" />,
    title: 'Share this post',
    description: 'Activity description goes here',
    pointsCount: 2,
    intentLink: 'https://twitter.com/intent/retweet?tweet_id=',
  },
  {
    icon: <ChatBubbleOutlineOutlinedIcon color="secondary" />,
    title: 'Leave a comment',
    description: 'Activity description goes here',
    pointsCount: 3,
    intentLink: 'https://twitter.com/intent/tweet?in_reply_to=',
  },
]

interface Props {
  questId?: string | null
}

function QuestActivityForm({ questId }: Props) {
  const { showAlert, me, setUsername } = useAppContext()
  const [showConfetti, setShowConfetti] = useState(false)
  const materialTheme = useTheme()
  const { currentPhase } = useCurrentPhaseContext()
  const { isMobile } = useResponsiveLayout()

  const {
    data,
    loading: questLoading,
    error: questError,
    refetch: questRefetch,
  } = useQuery(QUEST, {
    variables: {
      id: questId!,
    },
    skip: !questId,
  })

  const quest = useMemo(() => data?.quest, [data])

  const {
    ref: resizeRef,
    width: resizeWidth,
    height: resizeHeight,
  } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  const [verifyQuest, { loading }] = useMutation(VERIFY_QUEST)

  const handleVerify = useCallback(async () => {
    try {
      if (!quest) return

      if (!me?.name) {
        setUsername?.()
        return
      }
      if (!me?.twitterUserId) {
        redirectToTwitterAuth()
        return
      }
      const response = await verifyQuest({
        variables: {
          questId: quest.id,
        },
        refetchQueries: [
          { query: QUEST_DAILY_STATS },
          { query: ME },
          {
            query: QUEST_BY_PHASE_ID_STATS,
            variables: { phaseId: currentPhase?.id!, skip: !currentPhase?.id },
          },
        ],
        update(cache, { data }) {
          // todo need update return data on backend
          return updatePublishedQuestQuery(cache, data, quest)
        },
      })
      if (response?.data?.verifyQuest) {
        await questRefetch()
        EventBus.trigger(
          EventBus.actions.quests.updateActivity,
          response.data.verifyQuest,
        )
      }
      setShowConfetti(true)
      showAlert?.({
        isOpen: true,
        title: 'Quest successfully verified',
        color: AlertType.SUCCESS,
      })
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }, [
    quest,
    me,
    verifyQuest,
    currentPhase,
    showAlert,
    setUsername,
    questRefetch,
  ])

  const handleCopy = useCallback(
    (link: string) => {
      navigator.clipboard.writeText(link)
      showAlert?.({
        isOpen: true,
        title: 'The link was copied',
        color: AlertType.INFO,
      })
    },
    [showAlert],
  )

  const handleTwitterIntentLink = useCallback(
    (url: string) => {
      if (!me?.name) {
        setUsername?.()
        return
      }
      if (!me?.twitterUserId) {
        redirectToTwitterAuth()
        return
      }
      window.open(url, '_blank', 'noopener,noreferrer')
    },
    [me, setUsername],
  )

  const questExpired = useMemo(
    () =>
      !!(
        quest?.expirationDate &&
        DateTime.now() > DateTime.fromISO(quest.expirationDate)
      ),
    [quest],
  )

  // todo connect activities status
  const verificationQuestStatus = useMemo(
    () => (quest?.questActivity ? VerificationQuestStatus.Completed : null),
    [quest],
  )

  if (questLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (questError) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <Text body1 secondary sx={{ mb: 1 }}>
          Something went wrong
        </Text>
      </Box>
    )
  }
  return (
    <Box
      ref={resizeRef}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <BackgroundImage sx={{ backgroundImage: `url(${quest?.imageUrl})` }}>
        <GradientWrapper />
        <Box
          sx={{
            padding: 2,
            width: '100%',
          }}
        >
          <Text body3 secondary sx={{ mb: 3 }}>
            {getDate(quest.createdAt)}
          </Text>
          <Text ellipsis sx={{ color: 'white' }} title5>
            {quest.title}
          </Text>
          <Box maxHeight="350px" mt={1} overflow="auto">
            <ExpandableText content={quest?.content} maxChars={300} />
          </Box>
        </Box>
      </BackgroundImage>
      <ActivitiesWrapper p={2.5}>
        <Box
          display="flex"
          flexDirection="column"
          maxWidth="400px"
          width="100%"
        >
          <Box mb={3}>
            <Text sx={{ mb: 1.5 }} title3>
              Community activities
            </Text>
            <Box display="flex" flexWrap="wrap" gap={1.5}>
              <Box alignItems="center" display="flex" gap={0.75}>
                <FavoriteIcon color="error" fontSize="small" />
                <Text sx={{ fontWeight: 500 }}>
                  Activities verified:{' '}
                  <Text
                    as="span"
                    success={!!quest?.questActivity}
                    sx={{ fontWeight: 500 }}
                  >
                    {quest?.questActivity ? 3 : 0} / 3
                  </Text>
                </Text>
              </Box>
              <Divider flexItem orientation="vertical" sx={{ my: 0.5 }} />
              <Box alignItems="center" display="flex" gap={0.75}>
                <StarIcon color="warning" fontSize="small" />
                <Text sx={{ fontWeight: 500 }}>
                  Points earned:{' '}
                  <Text
                    as="span"
                    success={!!quest?.questActivity}
                    sx={{ fontWeight: 500 }}
                  >
                    {quest?.questActivity?.points || 0} / 6
                  </Text>
                </Text>
              </Box>
            </Box>
          </Box>

          <ActivitiesContainer>
            <HeaderWrapper p={2}>
              <img alt={'twitter logo'} src={twitterLogo} width={44} />
              <Text
                body2
                sx={{ color: 'white', fontWeight: 700, mb: 0.5, mt: 1 }}
              >
                X (formerly Twitter)
              </Text>
              <Box alignItems="center" display="flex" gap={0.5}>
                <ChainIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleCopy(
                      `https://twitter.com/i/web/status/${quest.twitterId}`,
                    )
                  }
                />
                <a
                  href={`https://twitter.com/i/web/status/${quest.twitterId}`}
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', overflow: 'hidden' }}
                  target="_blank"
                >
                  <Text ellipsis primary subheader1>
                    {`https://twitter.com/i/web/status/${quest?.twitterId}`}
                  </Text>
                </a>
              </Box>
            </HeaderWrapper>
            <Box display="flex" flexDirection="column" gap={1} p={1.5}>
              {QUEST_ACTIVITIES.map(activity => (
                <TwitterActivity
                  activity={activity}
                  verificationQuestStatus={verificationQuestStatus}
                  onTwitterIntentLink={() =>
                    handleTwitterIntentLink(
                      `${activity.intentLink}${quest.twitterId}`,
                    )
                  }
                />
              ))}
              <Tooltip title={questExpired && 'Quest expired'}>
                <Box>
                  <SubmitButton
                    disabled={questExpired || loading || !!quest?.questActivity}
                    sx={{ m: 0, height: '48px' }}
                    onClick={handleVerify}
                  >
                    <Box alignItems="center" display="flex" gap={1}>
                      <UnionIcon />
                      <Text sx={{ color: 'white' }} title4>
                        Verify
                      </Text>
                    </Box>
                  </SubmitButton>
                </Box>
              </Tooltip>
            </Box>
          </ActivitiesContainer>
        </Box>
      </ActivitiesWrapper>
      {showConfetti && (
        <Confetti
          colors={[
            materialTheme.palette.primary.main,
            materialTheme.palette.error.main,
            materialTheme.palette.success.main,
            materialTheme.palette.warning.main,
          ]}
          friction={0.99}
          gravity={0.05}
          height={resizeHeight}
          numberOfPieces={2000}
          recycle={false}
          tweenDuration={10000}
          width={resizeWidth}
        />
      )}
    </Box>
  )
}

export default QuestActivityForm
