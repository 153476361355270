import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useScrollToAnchorInContainer() {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1)
      const container = document.getElementById('section')
      const element = document.getElementById(elementId)
      if (container && element) {
        container.scrollTop = element.offsetTop - container.offsetTop
      }
    }
  }, [location])
}

export default useScrollToAnchorInContainer
