import { useCallback, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { GET_PUBLISHED_QUESTS } from 'graphql/quests/queries'
import { Quest, QuestActivity } from 'graphql/quests/types'
import EventBus from 'services/eventBus'

import { useEventBusSubscribe } from './index'

export default function usePublishedQuestList({ take = 5, order = 'DESC' }) {
  const [publishedQuestList, setPublishedQuestList] = useState<Quest[]>([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const [loadData, { error, loading }] = useLazyQuery(GET_PUBLISHED_QUESTS)

  const fetchData = useCallback(async () => {
    try {
      setPublishedQuestList([])
      setCount(0)
      setPage(1)

      const result = await loadData({
        variables: {
          take,
          order,
          page: 1,
        },
      })
      if (!result.data) return

      const rows = result.data?.getPublishedQuestList?.data || []
      const count = result.data?.getPublishedQuestList?.meta?.itemCount

      setPublishedQuestList([...rows])
      setCount(count)
    } catch (error) {
      // todo add error handler
    }
  }, [loadData, order, take])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const loadMore = useCallback(async () => {
    try {
      if (publishedQuestList.length >= count) return
      setPage(page + 1)

      const result = await loadData({
        variables: {
          order,
          take,
          page: page + 1,
        },
      })
      if (!result.data) return
      const rows = result.data.getPublishedQuestList?.data || []
      setPublishedQuestList(prevList => [...prevList, ...rows])
    } catch (error) {
      // todo add error handler
    }
  }, [publishedQuestList, count, page, loadData, order, take])

  const updatePublishedQuestList = useCallback(
    (questActivity: QuestActivity) =>
      setPublishedQuestList(prevItems =>
        prevItems.map(item =>
          item.id === questActivity.questId ? { ...item, questActivity } : item,
        ),
      ),
    [],
  )

  useEventBusSubscribe(
    EventBus.actions.quests.updateActivity,
    updatePublishedQuestList,
  )

  return {
    loadMore,
    loading,
    error,
    count,
    publishedQuestList,
    updatePublishedQuestList,
  }
}
