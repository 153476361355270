import React, { useMemo } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { URL_PARAMS } from 'constants/params'
import { LoginCode } from 'pages//loginCode'
import { ConfirmLoginPage } from 'pages/confirmLoginPage'
import Wave2Layout from 'pages/layouts/wave2Layout'
import { MonthlyReportPage } from 'pages/montlyReportPage'
import { SignIn } from 'pages/signIn'
import { SignUp } from 'pages/signUp'
import { TermsPage } from 'pages/termsPage'

import { Routes } from './routes'

export default function NotAuthorizedRouter() {
  const location = useLocation()

  const redirectPath = encodeURIComponent(
    `${location.pathname}${location.search}${location.hash}`,
  )

  const routes = useMemo(
    () => [
      { path: Routes.MONTHLY_REPORT, element: <MonthlyReportPage /> },
      { path: Routes.TERMS_PAGE, element: <TermsPage /> },
      {
        element: <Wave2Layout />,
        children: [
          {
            path: Routes.ROOT,
            element: (
              <Navigate
                replace
                to={`${Routes.SIGN_UP}?${URL_PARAMS.REDIRECT}=${redirectPath}`}
              />
            ),
          },
          {
            path: Routes.SIGN_UP,
            element: <SignUp />,
          },
          {
            path: Routes.SIGN_IN,
            element: <SignIn />,
          },
          {
            path: Routes.LOGIN_CODE,
            element: <LoginCode />,
          },
          {
            path: Routes.CONFIRM_LOGIN,
            element: <ConfirmLoginPage />,
          },
          {
            path: '*',
            element: (
              <Navigate
                replace
                to={`${Routes.SIGN_UP}?${URL_PARAMS.REDIRECT}=${redirectPath}`}
              />
            ),
          },
        ],
      },
    ],
    [redirectPath],
  )
  return useRoutes(routes)
}
