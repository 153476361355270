import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { Box, Button, Divider, FormControlLabel, Link } from '@mui/material'
import { AlertType } from 'components/alert/types'
import StyledCheckBox from 'components/ui/StyledCheckbox'
import SubmitButton from 'components/ui/SubmitButton'
import Text from 'components/ui/Text'
import { REDIRECT_PATH } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { useFormik } from 'formik'
import { CONFIRM_TERMS } from 'graphql/auth/mutation'
import useLogout from 'hooks/useLogout'
import { Routes } from 'router/routes'
import { getErrorMessage } from 'utils/error'
import * as yup from 'yup'

enum FIELDS {
  CONFIRN_NEWS_LETTER = 'confirmNewsletter',
  CONFIRM_SYSTEM_LETTER = 'confirmSystemLetter',
  CONFIRM_TERMS = 'confirmTerms',
}

interface InitialValues {
  [FIELDS.CONFIRM_TERMS]: boolean
  [FIELDS.CONFIRN_NEWS_LETTER]: boolean
  [FIELDS.CONFIRM_SYSTEM_LETTER]: boolean
}

const initialValues = {
  [FIELDS.CONFIRM_TERMS]: false,
  [FIELDS.CONFIRN_NEWS_LETTER]: false,
  [FIELDS.CONFIRM_SYSTEM_LETTER]: false,
}

function TermsAndConditions() {
  const navigate = useNavigate()
  const logout = useLogout()
  const { refetchMe, showAlert } = useAppContext()

  const [confirmTerms, { loading }] = useMutation(CONFIRM_TERMS)

  const validationSchema = useMemo(
    () =>
      yup.object({
        [FIELDS.CONFIRM_TERMS]: yup
          .boolean()
          .required('You must accept the terms and conditions')
          .oneOf([true], 'You must accept the terms and conditions'),
        [FIELDS.CONFIRM_SYSTEM_LETTER]: yup
          .boolean()
          .required(
            'You must acknowledge and consent to receiving system emails',
          )
          .oneOf(
            [true],
            'You must acknowledge and consent to receiving system emails',
          ),
      }),
    [],
  )

  const onSubmit = async (values: InitialValues) => {
    try {
      await confirmTerms({
        variables: {
          [FIELDS.CONFIRN_NEWS_LETTER]: values[FIELDS.CONFIRN_NEWS_LETTER],
        },
      })
      await refetchMe?.()

      const redirectPath = localStorage.getItem(REDIRECT_PATH) || Routes.ROOT

      localStorage.removeItem(REDIRECT_PATH)

      navigate(redirectPath)
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }

  const { values, handleChange, errors, handleSubmit, getFieldProps, touched } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    })

  return (
    <Box maxWidth="426px" style={{ textAlign: 'start' }} width="100%">
      <Text title1>Terms & Conditions</Text>
      <Text body2 sx={{ mt: 1.5, mb: 3 }}>
        You are agreeing with the terms by using the platform
      </Text>
      <form onSubmit={handleSubmit}>
        <FormControlLabel
          control={
            <StyledCheckBox
              size="small"
              terms
              {...getFieldProps(FIELDS.CONFIRM_TERMS)}
              checked={values[FIELDS.CONFIRM_TERMS]}
            />
          }
          label={
            <Text body4>
              I have read and agree to the{' '}
              <Link
                href={Routes.TERMS_PAGE}
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms & Conditions
              </Link>
            </Text>
          }
          sx={{ width: '100%', alignItems: 'start' }}
          onClick={handleChange}
        />
        <Text
          body4
          error
          sx={{
            mt: 0.5,
            mb: 1.5,
          }}
        >
          {touched[FIELDS.CONFIRM_TERMS] && errors[FIELDS.CONFIRM_TERMS]}
        </Text>

        <FormControlLabel
          control={
            <StyledCheckBox
              size="small"
              terms
              {...getFieldProps(FIELDS.CONFIRM_SYSTEM_LETTER)}
              checked={values[FIELDS.CONFIRM_SYSTEM_LETTER]}
            />
          }
          label={
            <Text body4>
              I acknowledge and consent to receiving system emails necessary for
              the operation and maintenance of my account
            </Text>
          }
          sx={{ width: '100%', alignItems: 'start' }}
          onClick={handleChange}
        />
        <Text
          body4
          error
          sx={{
            mt: 0.5,
            mb: 1.5,
          }}
        >
          {touched[FIELDS.CONFIRM_SYSTEM_LETTER] &&
            errors[FIELDS.CONFIRM_SYSTEM_LETTER]}
        </Text>
        <FormControlLabel
          control={
            <StyledCheckBox
              size="small"
              terms
              {...getFieldProps(FIELDS.CONFIRN_NEWS_LETTER)}
              checked={values[FIELDS.CONFIRN_NEWS_LETTER]}
            />
          }
          label={
            <Text body4>
              I would like to receive updates, offers, and newsletters via email
            </Text>
          }
          sx={{ width: '100%', alignItems: 'start' }}
          onClick={handleChange}
        />
        <Divider sx={{ mt: 3 }} />
        <SubmitButton disabled={loading} type="submit">
          Continue
        </SubmitButton>
      </form>
      <Button
        size="medium"
        sx={{
          mt: 2,
          width: '100%',
        }}
        variant="text"
        onClick={logout}
      >
        Logout
      </Button>
    </Box>
  )
}

export default TermsAndConditions
