import React, { useCallback, useMemo, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { useQuery } from '@apollo/client'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, FormControlLabel, RadioGroup } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { CompaniesBox } from 'components/companiesBox'
import { NodesTotalBox } from 'components/NodesTotalBox'
import StyledRadio from 'components/ui/StyledRadio'
import Text from 'components/ui/Text'
import { getFormattedNumber } from 'constants/number'
import {
  SYSTEM_DAILY_STATS_DATASET,
  SYSTEM_DAILY_STATS_GRAPH_DATA,
} from 'graphql/systemDailyStats/queries'
import { useResponsiveLayout } from 'hooks'
import { DateTime } from 'luxon'

import isUndefined from 'lodash/isUndefined'

import BridgedTokenChart from './Charts/bridgedTokenChart'
import CirculationChart from './Charts/circulationChart'
import NodesBarChart from './Charts/nodesBarChart'
import StakedTokenChart from './Charts/stakedTokenChart'
import ValidatorsBarChart from './Charts/validatorsBarChart'
import { ChartContainer, HeaderContainer } from './styles'

export interface Range {
  from: DateTime | null
  to: DateTime | null
}

enum BarChartKind {
  NODES = 'nodes',
  VALIDATORS = 'validators',
}

enum AreaChartKind {
  STAKED = 'staked',
  BRIDGED = 'bridged',
}

const DEFAULT_PERIOD_DAYS = 30

function GeneralStats() {
  const { isLargeDesktop } = useResponsiveLayout()
  const [barChartKind, setBarChartKind] = useState(BarChartKind.NODES)
  const [areaChartKind, setAreaChartKind] = useState(AreaChartKind.BRIDGED)

  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ days: DEFAULT_PERIOD_DAYS }),
    to: DateTime.now(),
  })

  const { data, loading } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const { ref: resizeRef, width: resizeWidth } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  const { data: dataset, loading: datasetLoading } = useQuery(
    SYSTEM_DAILY_STATS_DATASET,
  )

  const systemDailyStatsDataByFields = useMemo(
    () => data?.systemDailyStatsGraphData || {},
    [data],
  )

  const systemDailyStatsDataset = useMemo(
    () => dataset?.systemDailyStatsDataset || {},
    [dataset],
  )

  const renderDiffBlock = useCallback(
    (diff: number, percentage?: number, withoutArrow?: boolean) => {
      if (isUndefined(diff)) return null
      return (
        <Box alignItems="baseline" display="flex">
          {!withoutArrow && !!diff && (
            <div>
              {diff > 0 ? (
                <ArrowUpwardIcon color="success" sx={{ mr: '12px' }} />
              ) : (
                <ArrowDownwardIcon color="error" sx={{ mr: '12px' }} />
              )}
            </div>
          )}
          <Text
            error={diff < 0}
            primary
            success={diff > 0}
            sx={{ mr: 1.5 }}
            title1
          >
            {getFormattedNumber(diff)}
          </Text>
          <Text body0 error={diff < 0} primary success={diff > 0}>
            {percentage ? `(${percentage.toFixed(2)}%)` : ''}
          </Text>
        </Box>
      )
    },
    [],
  )
  // todo in circulation supply i got total supply
  const totalSupply = useMemo(
    () => systemDailyStatsDataset?.circulationSupply || 0,
    [systemDailyStatsDataset],
  )

  const bridgedTokens = useMemo(
    () => systemDailyStatsDataset?.bridgedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const stakedTokens = useMemo(
    () => systemDailyStatsDataset?.stackedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const handleChangeBarChart = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBarChartKind((event.target as HTMLInputElement).value as BarChartKind)
    },
    [],
  )

  const handleChangeAreaChart = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAreaChartKind(
        (event.target as HTMLInputElement).value as AreaChartKind,
      )
    },
    [],
  )

  if (loading || datasetLoading)
    return <CircularProgress size={20} sx={{ margin: '75px auto' }} />

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2.5} mb={2.5}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns={!isLargeDesktop ? '1fr' : 'repeat(3, 1fr)'}
          overflow="hidden"
          width="100%"
        >
          <ChartContainer
            p={2}
            ref={resizeRef}
            sx={{
              gridColumn: !isLargeDesktop ? '1 span' : '2 span',
              overflow: 'hidden',
            }}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              {areaChartKind === AreaChartKind.STAKED ? (
                <HeaderContainer>
                  <Text body2 sx={{ mb: 0.5 }}>
                    MNW Staked - Total
                  </Text>
                  <Text body3 secondary sx={{ fontWeight: 300 }}>
                    Number of all tokens staked
                  </Text>
                </HeaderContainer>
              ) : (
                <HeaderContainer>
                  <Text body2 sx={{ mb: 0.5 }}>
                    MNW Bridged - Total
                  </Text>
                  <Text body3 secondary sx={{ fontWeight: 300 }}>
                    Number of all bridged tokens
                  </Text>
                </HeaderContainer>
              )}
              <RadioGroup
                row={isLargeDesktop}
                sx={{ gap: 2, flexWrap: 'nowrap' }}
                value={areaChartKind}
                onChange={handleChangeAreaChart}
              >
                <FormControlLabel
                  control={<StyledRadio />}
                  label={<Text body3>Bridged</Text>}
                  sx={{ margin: 0 }}
                  value={AreaChartKind.BRIDGED}
                />
                <FormControlLabel
                  control={<StyledRadio />}
                  label={<Text body3>Staked</Text>}
                  sx={{ margin: 0 }}
                  value={AreaChartKind.STAKED}
                />
              </RadioGroup>
            </Box>
            {areaChartKind === AreaChartKind.STAKED ? (
              <StakedTokenChart
                chartData={systemDailyStatsDataByFields?.stackedTokenAmount}
                width={resizeWidth}
              />
            ) : (
              <BridgedTokenChart
                chartData={systemDailyStatsDataByFields?.bridgedTokenAmount}
                diff={systemDailyStatsDataByFields?.bridgedTokenDailyDiff}
                width={resizeWidth}
              />
            )}
          </ChartContainer>

          <ChartContainer p={2}>
            <HeaderContainer>
              <Text body2 sx={{ mb: 0.5 }}>
                MNW Staked - Circul., %
              </Text>

              <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                Percentage tokens bridged of circulation supply
              </Text>
            </HeaderContainer>

            <CirculationChart
              bridgedTokens={bridgedTokens}
              legendTitles={['Staked', 'Bridged', 'Total Supply']}
              stakedTokens={stakedTokens}
              totalSupply={totalSupply}
            />
          </ChartContainer>

          <ChartContainer p={2}>
            <HeaderContainer
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  Running nodes
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  The number of all running nodes
                </Text>
              </Box>

              <Text primary sx={{ fontWeight: 500 }} title1>
                {systemDailyStatsDataset?.totalActiveNodesCount || 0}
              </Text>
            </HeaderContainer>
          </ChartContainer>

          <ChartContainer p={2}>
            <HeaderContainer
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  Nodes ++
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  Nodes added/removed on last day
                </Text>
              </Box>

              <Text primary sx={{ fontWeight: 500 }} title1>
                {renderDiffBlock(
                  systemDailyStatsDataset?.nodesDailyDiff ?? 0,
                  systemDailyStatsDataset?.nodesDailyDIffPercentage ?? 0,
                )}
              </Text>
            </HeaderContainer>
          </ChartContainer>

          <ChartContainer p={2}>
            <HeaderContainer
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  MNW Staked - Change
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  The difference in the amount of staked tokens over the last
                  day
                </Text>
              </Box>
              <Text primary sx={{ fontWeight: 500 }} title1>
                {renderDiffBlock(
                  systemDailyStatsDataset?.tokensDailyDiff ?? 0,
                  systemDailyStatsDataset?.tokensDailyDiffPercentage ?? 0,
                )}
              </Text>
            </HeaderContainer>
          </ChartContainer>
          <ChartContainer
            p={2}
            sx={{
              height: '350px',
              gridColumn: !isLargeDesktop ? '1 span' : '3 span',
              overflow: 'hidden',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              minHeight="60px"
              pb={2}
              width="100%"
            >
              {barChartKind === BarChartKind.NODES ? (
                <HeaderContainer>
                  <Text body2 sx={{ mb: 0.5 }}>
                    Node Staked - Total
                  </Text>
                  <Text body3 secondary sx={{ fontWeight: 300 }}>
                    Number of all nodes staked
                  </Text>
                </HeaderContainer>
              ) : (
                <HeaderContainer>
                  <Text body2 sx={{ mb: 0.5 }}>
                    Register validators
                  </Text>
                </HeaderContainer>
              )}

              <RadioGroup
                row={isLargeDesktop}
                sx={{ gap: 2, flexWrap: 'nowrap' }}
                value={barChartKind}
                onChange={handleChangeBarChart}
              >
                <FormControlLabel
                  control={<StyledRadio />}
                  label={<Text body3>Nodes</Text>}
                  sx={{ margin: 0 }}
                  value={BarChartKind.NODES}
                />
                <FormControlLabel
                  control={<StyledRadio />}
                  label={<Text body3>Validators</Text>}
                  sx={{ margin: 0 }}
                  value={BarChartKind.VALIDATORS}
                />
              </RadioGroup>
            </Box>

            {barChartKind === BarChartKind.NODES ? (
              <NodesBarChart
                chartDataStaked={
                  systemDailyStatsDataByFields?.stackedNodesCount
                }
                chartDataUnstaked={
                  systemDailyStatsDataByFields?.unstakedNodesCount
                }
              />
            ) : (
              <ValidatorsBarChart
                chartDataClosed={
                  systemDailyStatsDataByFields?.closedValidatorsCount
                }
                chartDataRegistered={
                  systemDailyStatsDataByFields?.registeredValidatorsCount
                }
              />
            )}
          </ChartContainer>
        </Box>
      </Box>
      <CompaniesBox />
      <NodesTotalBox />
    </>
  )
}

export default React.memo(GeneralStats)
