import React, { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { AlertTitle, Box, Snackbar } from '@mui/material'
import Alert from 'components/alert/alert'
import { AlertMessage, AlertType } from 'components/alert/types'
import CustomModal from 'components/modal/customModal'
import { ALERT_DELAY } from 'constants/params'
import { nameValidationReg } from 'constants/validations'
import { useAppContext } from 'context/AppContext'
import { useFormik } from 'formik'
import { LowerTitle, StyledInput, SubmitButton, Title } from 'globalStyles'
import { UPDATE_ME } from 'graphql/validators/mutation'
import { useResponsiveLayout } from 'hooks'
import { getErrorMessage } from 'utils/error'
import * as yup from 'yup'

enum FIELD {
  NAME = 'name',
}

interface InitialValues {
  [FIELD.NAME]: string
}

interface Props {
  isOpen?: boolean
  onClose?: () => void
}

function SetUsernameModal({ isOpen, onClose }: Props) {
  const { me, refetchMe } = useAppContext()
  const { isMobile } = useResponsiveLayout()

  const [updateMeMutation] = useMutation(UPDATE_ME)

  const [loading, setLoading] = useState<boolean>(false)

  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const initialValues: InitialValues = {
    [FIELD.NAME]: me?.name || '',
  }

  const validationSchema = useMemo(() => {
    return yup.object({
      [FIELD.NAME]: yup
        .string()
        .required('Please enter your name')
        .matches(
          nameValidationReg,
          'Name can contain only letters, numbers and _',
        ),
    })
  }, [])

  const onSubmitHandler = async (values: InitialValues) => {
    try {
      setLoading(true)
      await updateMeMutation({
        variables: {
          ...values,
        },
      })
      await refetchMe?.()
      onClose?.()
      setAlert({
        isOpen: true,
        title: me?.name
          ? 'You name successfully updated'
          : 'You name successfully created',
        color: AlertType.SUCCESS,
      })
    } catch (error) {
      setAlert({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, isValid, handleSubmit, handleChange, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: onSubmitHandler,
    })

  const handleCloseModal = useCallback(() => {
    onClose?.()
    resetForm()
  }, [onClose, resetForm])

  return (
    <>
      <CustomModal
        handleClose={handleCloseModal}
        open={isOpen}
        sx={{ width: isMobile ? '90%' : '570px' }}
      >
        <Box sx={{ maxWidth: '370px', width: '100%', pb: '30px' }}>
          <Title sx={{ textAlign: 'center' }}>
            {me?.name
              ? 'Update username'
              : 'One more step to join our community'}
          </Title>
          <form onSubmit={handleSubmit}>
            <Box>
              <StyledInput
                name={FIELD.NAME}
                placeholder="Name"
                value={values?.[FIELD.NAME]}
                onChange={handleChange}
              />
              <LowerTitle
                sx={{
                  my: '8px',
                  color: 'red',
                  height: '24px',
                }}
              >
                {errors?.[FIELD.NAME]}
              </LowerTitle>
            </Box>
            <SubmitButton
              disabled={loading || !isValid}
              sx={{ mt: 1 }}
              type="submit"
            >
              Submit
            </SubmitButton>
          </form>
        </Box>
      </CustomModal>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert?.isOpen}
        onClose={() => setAlert(prevState => ({ ...prevState, isOpen: false }))}
      >
        <Alert
          severity={alert?.color}
          sx={{ width: '100%' }}
          onClose={() =>
            setAlert(prevState => ({ ...prevState, isOpen: false }))
          }
        >
          <AlertTitle sx={{ textAlign: 'left' }}>{alert.title}</AlertTitle>
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SetUsernameModal
