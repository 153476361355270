import React from 'react'

import { Box, Link, Tooltip } from '@mui/material'
import { CodeBlock } from 'components/codeBlock'
import { StyledNodeLink } from 'globalStyles'

import { HighlightText, Subtitle, Title, YoutubeCustomizedIcon } from './styles'

function WindowsInstructions() {
  return (
    <Box width="100%">
      <Box alignItems="center" display="flex" sx={{ mt: 4 }}>
        <Title>First-time installation</Title>
        <Tooltip arrow title={'Click to see the video instructions.'}>
          <Link
            href={'https://youtu.be/YGOPCRRngrMTrustMNW'}
            sx={{ display: 'flex', ml: 1, mt: '3px' }}
            target="_blank"
          >
            <YoutubeCustomizedIcon />
          </Link>
        </Tooltip>
      </Box>
      <Subtitle>
        1. Install Latest NVM version (v1.1.11 as of time of writing):
      </Subtitle>
      <Subtitle sx={{ mt: 1 }}>
        Navigate to the{' '}
        <StyledNodeLink
          href="https://github.com/coreybutler/nvm-windows/releases"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          link
        </StyledNodeLink>{' '}
      </Subtitle>
      <Subtitle sx={{ mt: 1 }}>
        Scroll to the bottom to the <HighlightText>Assets</HighlightText>{' '}
        section and download <HighlightText>nvm-setup.exe.</HighlightText>
        Follow all the steps of installation after downloading.
      </Subtitle>
      <Subtitle>
        2. Open the terminal and install latest version of Node engine- v20.9.0
        using NVM:{' '}
      </Subtitle>
      <CodeBlock code={'nvm install  v20.9.0'} />
      <Subtitle>3. Make sure you use correct version of it: </Subtitle>
      <CodeBlock code={'nvm use v20.9.0'} />
      <CodeBlock code={'nvm list'} />
      <Subtitle sx={{ mt: '-13px' }}>
        Note: Should show correct version.{' '}
      </Subtitle>
      <Subtitle>4. Install TrustMNW CLI: </Subtitle>
      <CodeBlock code={'npm install @morpheusnetwork/trustmnw-cli -g'} />
      <Subtitle sx={{ mt: '-13px' }}>
        Note: Updating can also be done using this command.
      </Subtitle>
      <Subtitle>5. Run Morpheus CLI:</Subtitle>
      <CodeBlock code={'trustmnw-cli login'} />
      <Subtitle>
        The system will ask for your email address, and you will need to enter
        the verification code sent to your email address.
      </Subtitle>
      <Subtitle>6. Check status and confirm if it’s running:</Subtitle>
      <CodeBlock code={'trustmnw-cli status'} />
      <Subtitle>
        To exit status - just close tab of your terminal or use hotkey “ctrl +
        c”.
      </Subtitle>
      <Subtitle>
        7. Check-in 10 minutes on the web if it says Node is running.
      </Subtitle>
      <Title id="update" sx={{ mt: 4 }}>
        Update
      </Title>
      <Subtitle>1.Update CLI:</Subtitle>
      <CodeBlock code={'npm update @morpheusnetwork/trustmnw-cli -g'} />
      <Subtitle>2.Restart CLI:</Subtitle>
      <CodeBlock code={'trustmnw-cli restart'} />
      <Subtitle>3. Help command:</Subtitle>
      <CodeBlock code={'trustmnw-cli restart help'} />
    </Box>
  )
}

export default WindowsInstructions
