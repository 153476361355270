import React from 'react'
import { useNavigate } from 'react-router-dom'

import QuestActivityForm from 'components/blocks/questAcivityForm'
import CustomModal from 'components/modal/customModal'
import QuestRightPanel from 'components/questRightPanel'
import { useResponsiveLayout } from 'hooks'

interface Props {
  questId?: string | null
}

function QuestModal({ questId }: Props) {
  const navigate = useNavigate()
  const { isMobile } = useResponsiveLayout()

  const handleClose = () => {
    navigate('?', { replace: true })
  }

  if (!questId) return null

  if (isMobile) {
    return <QuestRightPanel isOpen questId={questId} onClose={handleClose} />
  }
  return (
    <CustomModal
      handleClose={handleClose}
      open
      sx={{
        width: '80%',
        overflow: 'hidden',
        padding: 0,
        maxHeight: '660px',
        minHeight: '660px',
      }}
    >
      <QuestActivityForm questId={questId} />
    </CustomModal>
  )
}

export default QuestModal
