import { createContext, useContext } from 'react'

import { AlertMessage } from 'components/alert/types'

interface Validator {
  id: string
  email: string
  wallet?: string
  stakedNode?: string
  isApproved: boolean
  nodesLimit: number | null
  twitterUserId?: string
  twitterUsername?: string
  twitterProfileImageUrl?: string
  name: string
  totalPoints: number
  nodeVersion?: string
  freezingPeriodEndAt?: string
  confirmedNewsletter?: boolean
}

interface AppContextValue {
  me?: Validator
  refetchMe?: (variables?: any) => Promise<any>
  setUsername?: () => void
  showAlert?: (alertMessage: AlertMessage) => void
}

export const AppContext = createContext<AppContextValue>({})

export function useAppContext() {
  return useContext(AppContext)
}
