import { gql } from '@apollo/client'

import { NEWS_TYPE_FIELDS } from './fragments'

export const ALL_PUBLISHED_NEWS = gql`
  query AllPublishedNews(
    $search: String
    $order: String
    $page: Int
    $take: Int
  ) {
    allPublishedNews(search: $search, order: $order, page: $page, take: $take) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        ...NewsTypeFields
      }
    }
  }
  ${NEWS_TYPE_FIELDS}
`

export const NEWS = gql`
  query News($id: ID!) {
    news(id: $id) {
      ...NewsTypeFields
    }
  }
  ${NEWS_TYPE_FIELDS}
`
