import React, { useContext, useMemo } from 'react'

import { Tabs } from '@mui/material'
import { ReactComponent as InstructionDM } from 'assets/icons/instruction_icon_dark.svg'
import { ReactComponent as InstructionLM } from 'assets/icons/instruction_icon_light.svg'
import { ReactComponent as MacOsIconDM } from 'assets/icons/macOs_icon_dark.svg'
import { ReactComponent as MacOsIconLM } from 'assets/icons/macOs_icon_light.svg'
import { ReactComponent as WindowsIconDM } from 'assets/icons/microsoft_icon_dark.svg'
import { ReactComponent as WindowsIconLM } from 'assets/icons/microsoft_icon_light.svg'
import { ReactComponent as LinuxIconDM } from 'assets/icons/ubuntu_icon_dark.svg'
import { ReactComponent as LinuxIconLM } from 'assets/icons/ubuntu_icon_light.svg'
import ContentBox from 'components/ContentBox'
import { ThemeContext } from 'context/themeMode'
import { useScrollToAnchor } from 'hooks'

import map from 'lodash/map'

import LinuxInstructions from './platforms/linux'
import MacOSInstructions from './platforms/macOS'
import WindowsInstructions from './platforms/windows'
import { PlatformTab } from './styles'

const InstructionPage = () => {
  const mode = useContext(ThemeContext)

  useScrollToAnchor()

  const isDarkMode = mode.mode === 'dark'
  const InstructionIcon = isDarkMode ? InstructionDM : InstructionLM
  const MacIcon = isDarkMode ? MacOsIconDM : MacOsIconLM
  const LinuxIcon = isDarkMode ? LinuxIconDM : LinuxIconLM
  const WindowsIcon = isDarkMode ? WindowsIconDM : WindowsIconLM

  const [tab, setTab] = React.useState(0)

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const platforms = useMemo(
    () => [
      { label: 'Linux', icon: <LinuxIcon /> },
      { label: 'MacOs', icon: <MacIcon /> },
      { label: 'Windows', icon: <WindowsIcon /> },
    ],
    [LinuxIcon, MacIcon, WindowsIcon],
  )

  return (
    <ContentBox
      sx={{
        pt: 2,
        alignItems: 'center',
      }}
    >
      <InstructionIcon />
      <Tabs centered sx={{ mb: 2 }} value={tab} onChange={handleChangeTab}>
        {map(platforms, ({ icon, label }, index) => (
          <PlatformTab
            icon={icon}
            iconPosition="start"
            key={label}
            label={label}
            selected={tab === index}
          />
        ))}
      </Tabs>
      {tab === 0 && <LinuxInstructions />}
      {tab === 1 && <MacOSInstructions />}
      {tab === 2 && <WindowsInstructions />}
    </ContentBox>
  )
}

export default InstructionPage
