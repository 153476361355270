import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { Box, Divider } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import CustomModal from 'components/modal/customModal'
import Text from 'components/ui/Text'
import { SubmitButton } from 'globalStyles'
import { NEWS } from 'graphql/news/queries'
import { getDate } from 'utils/date'

interface Props {
  newsId?: string | null
}

function NewsModal({ newsId }: Props) {
  const navigate = useNavigate()
  const { data, loading, error } = useQuery(NEWS, {
    variables: {
      id: newsId!,
    },
    skip: !newsId,
  })

  const news = useMemo(() => data?.news, [data])

  const handleClose = () => {
    navigate('?', { replace: true })
  }

  if (!news) return null

  return (
    <CustomModal
      handleClose={handleClose}
      open
      sx={{
        maxWidth: '570px',
        width: '90%',
        maxHeight: '90%',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="140px"
        textAlign="left"
        width={1}
      >
        {!error && !loading && (
          <Box flexGrow={1} height="100%" overflow="auto" width="100%">
            <Text body1 secondary sx={{ mb: 1 }}>
              {getDate(news?.createdAt)}
            </Text>
            <Text body0 sx={{ mb: 1 }}>
              {news?.title}
            </Text>
            <Box>
              <Text body4 secondary>
                {news?.content}
              </Text>
            </Box>
          </Box>
        )}

        {loading && <CircularProgress size={20} />}

        {error && (
          <Text body1 secondary sx={{ mb: 1 }}>
            Something went wrong
          </Text>
        )}
      </Box>

      <Divider flexItem sx={{ my: 3 }} />
      <SubmitButton sx={{ mb: '10px', mt: 0 }} onClick={handleClose}>
        Got it!
      </SubmitButton>
    </CustomModal>
  )
}

export default NewsModal
