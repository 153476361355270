import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FavoriteIcon from '@mui/icons-material/Favorite'
import StarIcon from '@mui/icons-material/Star'
import { Box, Divider } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import ExpirationMessage from 'components/ExpirationMessage'
import Text from 'components/ui/Text'
import { URL_PARAMS } from 'constants/params'
import { Quest } from 'graphql/quests/types'
import usePublishedQuestList from 'hooks/usePublishedQuestList'
import { DateTime } from 'luxon'

import { NewsCard } from './styles'

function PublishedQuestList() {
  const navigate = useNavigate()

  const { publishedQuestList, loadMore, count, loading, error } =
    usePublishedQuestList({
      take: 5,
      order: 'DESC',
    })

  const handleOpenQuestModal = useCallback(
    (id: string) => {
      navigate(`?${URL_PARAMS.QUEST}=${id}`)
    },
    [navigate],
  )

  const loaded = !loading && !error
  const noPublishedQuests = loaded && !publishedQuestList?.length

  const renderFooter = useCallback(() => {
    if (count === publishedQuestList?.length) return null

    return (
      <Box display="flex" justifyContent="center" py={4}>
        <CircularProgress size={25} />
      </Box>
    )
  }, [count, publishedQuestList])

  const renderItemContent = useCallback(
    (index: number, quest: Quest) => (
      <NewsCard
        completed={!!quest?.questActivity}
        display="flex"
        expired={
          DateTime.fromISO(quest.expirationDate) <= DateTime.now() &&
          !!quest?.questActivity
        }
        gap={2}
        justifyContent="space-between"
        key={quest.id}
        mt={1.5}
        p={2}
        onClick={() => handleOpenQuestModal(quest.id)}
      >
        <Box height={1} overflow="hidden">
          {quest?.questActivity ? (
            <Box alignItems="center" display="flex" gap={0.75}>
              <CheckCircleIcon
                color="success"
                sx={{ width: '12px', height: '12px' }}
              />
              <Text body3 success>
                Quest completed
              </Text>
            </Box>
          ) : (
            <ExpirationMessage expirationDate={quest.expirationDate} />
          )}
          <Text ellipsis sx={{ mt: 1.5, mb: 0.5 }} title4>
            {quest.title}
          </Text>
          <Text body4 ellipsis secondary sx={{ md: 1.5 }}>
            {quest.content}
          </Text>
          <Box display="flex" gap={1.5} mt={1.5}>
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              justifyContent="space-between"
            >
              <Box alignItems="center" display="flex" gap={1}>
                <FavoriteIcon
                  color="error"
                  sx={{ width: '12px', height: '13px' }}
                />
                <Text body3>
                  Activities verified:{' '}
                  <Text as="span" body3 success={!!quest?.questActivity}>{`${
                    quest?.questActivity ? 3 : 0
                  } / 3`}</Text>
                </Text>
              </Box>
            </Box>
            <Divider flexItem orientation="vertical" sx={{ my: 0.25 }} />
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              justifyContent="space-between"
            >
              <Box alignItems="center" display="flex" gap={1}>
                <StarIcon
                  color="warning"
                  sx={{ width: '12px', height: '13px' }}
                />
                <Text body3>
                  Points earned:{' '}
                  <Text as="span" body3 success={!!quest?.questActivity}>{`${
                    quest?.questActivity?.points || 0
                  } / 6`}</Text>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        {quest?.imageUrl && (
          <Box
            alt={quest.title}
            component="img"
            loading="lazy"
            src={quest.imageUrl}
            sx={{
              borderRadius: '6px',
              height: '100px',
              minWidth: '100px',
              maxWidth: '100px',
              objectFit: 'cover',
            }}
          />
        )}
      </NewsCard>
    ),
    [handleOpenQuestModal],
  )

  return (
    <>
      {noPublishedQuests && (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Text>No available quests</Text>
        </Box>
      )}
      <Virtuoso
        components={{
          Footer: renderFooter,
        }}
        data={publishedQuestList}
        endReached={loadMore}
        itemContent={renderItemContent}
        style={{ height: '100%' }}
        totalCount={count}
      />
    </>
  )
}

export default PublishedQuestList
