export const CONTRACTS = {
  MASTER_ADDRESS: String(process.env.REACT_APP_MASTER_CONTRACT_ADDRESS),
  TOKEN_ADDRESS: String(process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS),
}

export const WEB3 = {
  WALLET_CONNECT_BRIDGE: process.env.REACT_APP_WALLET_BRIDGE,
  WALLET_CONNECT_NAME: process.env.REACT_APP_WALLET_NAME,
  POLLING_INTERVAL:
    parseInt(String(process.env.REACT_APP_WALLET_POLLING_INTERVAL), 10) || 0,
  CHAIN_ID: parseInt(String(process.env.REACT_APP_WALLET_CHAIN_ID), 10) || 0,
  RPC_URL: String(process.env.REACT_APP_RPC_URL),
  POLYGONSCAN_URL: String(process.env.REACT_APP_POLYGONSCAN_URL),
}

export const API = {
  URL: process.env.REACT_APP_API_URL,
}

export const TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID

export const IS_STAGING = process.env.REACT_APP_ENV === 'staging'

export const ONESIGNAL = {
  APP_ID: process.env.REACT_APP_ONESIGNAL_APP_ID || '',
  SAFARI_WEB_ID: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID || '',
}
